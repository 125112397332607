jQuery(document).ready(function ($) {
  /* ==================== Fonts ==================== */
  const WebFont = require("webfontloader");

  WebFont.load({
    google: {
      families: ["Poppins:300,400,600,700,900", ""],
    },
  });

  /* ==================== Menus ==================== */

  const mobileMenuToggle = document.querySelectorAll(".navToggle"),
    mobileMenu = document.getElementById("mobileNav"),
    header = document.querySelector("#header"),
    subMenuItems = document.querySelectorAll(".menu-item-has-children"),
    mobileSubMenuItems = document.querySelectorAll(
      "#mobileNav .menu-item-has-children"
    ),
    adminBar = document.getElementById("wpadminbar");

  mobileSubMenuItems.forEach((element) => {
    element.addEventListener("click", toggleSubMenu);
  });

  function toggleSubMenu() {
    this.classList.toggle("active");
  }

  const setHeaderHeight = () => {
    let headerOffset = header.getBoundingClientRect().bottom;
    let adminBarHeight;
    if (adminBar) {
      adminBarHeight = adminBar.offsetHeight;
      //header.style.marginTop = `${adminBarHeight}px`;
    } else {
      adminBarHeight = 0;
    }
    if (headerOffset && adminBarHeight !== null) {
      mobileMenu.style.top = `0px`;
      //mobileMenu.style.minHeight = `calc(100vh - 0px)`;
    }
  };

  // Initialize header and mobile menu height
  setHeaderHeight();

  // Listen for window resizing to update header and mobile menu height as screen size changes
  addEventListener("resize", (event) => {
    setHeaderHeight();
  });

  mobileMenuToggle.forEach((element) => {
    element.addEventListener("click", () => {
      element.classList.toggle("is-active");
      mobileMenu.classList.toggle("menu-open");
    });
    document.querySelector(".menu-backdrop").addEventListener("click", () => {
      mobileMenu.classList.remove("menu-open");
    });
  });

  $(document).on('click', '.scrollDown', function() {
    var currentSection = $(this).closest('section');
    var nextSection = currentSection.nextAll('section').first();
    
    if (nextSection.length) {
        $('html, body').animate({
            scrollTop: nextSection.offset().top
        }, 'fast');
    }
  });



  //Set height of boxes for expertise slider to be the same to position pagination bullets on them.
  function setEqualHeight(className) {
    var maxHeight = 0;
    
    // Find the maximum height
    $(className).each(function() {
        var height = $(this).outerHeight();
        if (height > maxHeight) {
            maxHeight = height;
        }
    });
    
    // Set each element's height to the maximum height
    $(className).each(function() {
        $(this).css('height', maxHeight + 'px');
    });

    //Set top value for pagination to sit in bottom of the box
    if ($(window).width() > 768) {
      $('.expertise-slider .swiper-pagination').css('top', maxHeight + 'px');
    }

  }

  setEqualHeight('.expertise-slider .swiper-slide .inner');

  //Calculate heights on window resize
  window.addEventListener('resize', function() {
    setEqualHeight('.expertise-slider .swiper-slide .inner');
  });


  $('.openShare').on('click', function() {
    $(this).parent().find('.share-buttons').toggleClass('active');
  });

  
});


